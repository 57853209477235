import { OrderStatusLabel } from "constants/orderStatus";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getServices } from "services/api/connection";

const StatusFilter = (props) => {
  const marketInitialValue = props.marketplaceValue;
  const sendInfo = props.onSelect;
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [services, setServices] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState(
    OrderStatusLabel.filter((it) => it.needToShow).map((res) => ({
      ...res,
      isCheck: false,
    }))
  );
  const isViewOrderStatus = props?.isViewOrderStatus;
  const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState([]);
  const [selectedMarketStatus, setSelectedMarketStatus] = useState([]);
  const [rerenderList, setrerenderList] = useState(true);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    getDeliveryServices();
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (props.orderValue?.length || props.deliveryValue?.length) {
      const orderSelected = props?.orderValue
        ? props.orderValue.split(",").map((item) => Number(item))
        : [];
      const deliverySelected = props?.deliveryValue
        ? props.deliveryValue.split(",").map((item) => Number(item))
        : [];
      const selected = [...orderSelected, ...deliverySelected];
      let statuses = orderStatuses.map((it) => {
        const isChecked = Array.isArray(it.value)
          ? it.value.some((val) => selected.includes(val))
          : selected.includes(it.value);
        return { ...it, isCheck: isChecked };
      });
      setOrderStatuses(statuses);
      setSelectedOrderStatus(orderSelected);
      setSelectedDeliveryStatus(deliverySelected);
    }
  }, [props.orderValue, props.deliveryValue]);

  useEffect(() => {
    if (marketInitialValue && marketInitialValue?.length) {
      let el = services.map((it) => {
        if (marketInitialValue.includes(it.id)) {
          return { ...it, isCheck: true };
        }
        return { ...it, isCheck: false };
      });
      setServices(el);
    }
  }, [marketInitialValue]);

  const getDeliveryServices = async () => {
    await getServices().then((res) => {
      let data = res.data;
      if (isMounted) {
        // const deliveryServices = data?.filter(
        //   (ele) => !["Postmates"].includes(ele.name)
        // );
        const deliveryServices = data;
        let selectedDeliveryServices, selectedDeliveryServiceIds;
        if (props?.selectedServiceIds && props?.selectedServiceIds?.length) {
          selectedDeliveryServices = deliveryServices.map((it) => ({
            ...it,
            isCheck: props?.selectedServiceIds.includes(it.id)
              ? true
              : false,
          }));
          selectedDeliveryServiceIds = props?.selectedServiceIds;
        } else {
          selectedDeliveryServices = deliveryServices.map((it) => ({
            ...it,
            isCheck: ["Garcon"].includes(it.name) ? false : true,
          }));
          selectedDeliveryServiceIds = data
            ?.filter((ele) => !["Garcon"].includes(ele.name))
            .map((ele) => {
              return ele.id;
            });
        }
        setServices(selectedDeliveryServices);
        setSelectedMarketStatus(selectedDeliveryServiceIds);
      }
    });
  };

  const onCheckOrderStatus = (data) => {
    let prevVal = selectedOrderStatus;
    let prevVal2 = selectedDeliveryStatus;
    if (Array.isArray(data)) {
      const idx = prevVal2.indexOf(data[0]);
      if (idx > -1) prevVal2?.splice(idx, data.length);
      else prevVal2?.push(data);
    } else if (prevVal?.includes(data)) {
      const idx = prevVal?.findIndex((it) => it === data);
      if (idx > -1) prevVal?.splice(idx, 1);
    } else prevVal?.push(data);
    setSelectedOrderStatus(prevVal);
    setSelectedDeliveryStatus(prevVal2);
  };

  const onCheckMarketStatus = (data) => {
    let prevVal = selectedMarketStatus;
    if (prevVal?.includes(data)) {
      let idx = prevVal?.findIndex((it) => it === data);
      if (idx > -1) prevVal?.splice(idx, 1);
    } else 
      prevVal?.push(data);
    setSelectedMarketStatus(prevVal);
  };

  const onSubmit = () => {
    sendInfo({
      orderStatus: selectedOrderStatus,
      deliveryStatus: selectedDeliveryStatus,
      marketplaceStatus: selectedMarketStatus,
    });
    setToggleDropdown(false);
  };

  const onAllSelect = () => {
    setrerenderList(false);
    let el = orderStatuses.map((it) => ({ ...it, isCheck: false }));
    setOrderStatuses(el);
    let elm = services.map((it) => ({ ...it, isCheck: false }));
    setServices(elm);

    setSelectedOrderStatus([]);
    setSelectedDeliveryStatus([]);
    setSelectedMarketStatus([]);

    sendInfo({
      orderStatus: null,
      deliveryStatus: null,
      marketplaceStatus: null,
    });
    setToggleDropdown(false);
  };

  const changeToggle = (data) => {
    setToggleDropdown(data);
    if (data === true) setrerenderList(true);
  };

  return (
    <Dropdown show={toggleDropdown} onToggle={changeToggle}>
      <Dropdown.Toggle
        id="allorders"
        className={
          (selectedOrderStatus && selectedOrderStatus?.length) ||
          (selectedMarketStatus && selectedMarketStatus?.length)
            ? "filter__applied"
            : ""
        }
      >
        All Orders
      </Dropdown.Toggle>
      {rerenderList ? (
        <Dropdown.Menu>
          <div className="dropdown__action__wrapper">
            <div className="main__heading">
              <Dropdown.Item onClick={onAllSelect}>
                <p>All Orders</p>
              </Dropdown.Item>
            </div>

            {isViewOrderStatus && orderStatuses && orderStatuses.length ? (
              <div className="sortBy__orderStatus">
                <div className="sub__heading">
                  <p>Order Status</p>
                </div>
                <div className="row no-gutters">
                  {orderStatuses.map((odrSts, idx) => (
                    <div className="col-md-12 mb-3" key={idx}>
                      <div className="custom-checkbox-wrapper d-flex">
                        <label className="custom-checkbox-container">
                          {odrSts.label}
                          <input
                            type="checkbox"
                            onClick={() => onCheckOrderStatus(odrSts.value)}
                            defaultChecked={odrSts.isCheck}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {services && services.length ? (
              <div className="sortBy__marketPlaces">
                <div className="sub__heading">
                  <p>Marketplaces</p>
                </div>
                <div className="row">
                  {services.map((srv, idx) => (
                    <div className="col-md-12 mb-3" key={idx}>
                      <div className="custom-checkbox-wrapper d-flex">
                        <label className="custom-checkbox-container">
                          {srv.name}
                          <input
                            type="checkbox"
                            onClick={() => onCheckMarketStatus(srv.id)}
                            defaultChecked={srv.isCheck}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="button__wrapper">
              <button className="btn apply-btn" onClick={onSubmit}>
                Apply
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  );
};

export default StatusFilter;
